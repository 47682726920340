<template>
    <div class="cart_line-item">
        <div class="cart_line-item--label">
            <div v-if="lineItem.itemnum"><b>{{ lineItem.itemnum }}</b></div>
            <div>{{ lineItem.name }}</div>
            <div v-if="lineItem.extdata && lineItem.extdata.e_VPE"><i>VPE: {{ lineItem.extdata.e_VPE.replace('*', '') }}</i></div>
        </div>
        <div class="cart_line-item--price">{{ toPrice(lineItem.sellingprice) }}</div>
        <div class="cart_line-item--quantity">
            <item-quantity
                :amount="lineItem.amount"
                :vpe="vpe"
                :obligatedVpe="obligatedVpe"
                :allowZero="false"
                @on-change-quantity="onChangeQuantity"
            />
        </div>
        <div class="cart_line-item--total-price">{{ toPrice(lineItem.total) }}</div>
        <div class="cart_line-item--actions">
            <v-btn icon @click="onRemoveItemFromCartClick">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ItemQuantity from '../item/ItemQuantity.vue';
import _ from 'lodash';
import { PriceHelper } from '../../../../helper/price.helper';

export default {
    components: {
        ItemQuantity,
    },

    props: {
        cartId: {
            type: Number,
            required: true,
        },
        lineItemId: {
            type: String,
            required: true,
        },
        lineItem: {
            type: Object,
            required: true,
        },
    },

    computed: {
        vpe() {
            if (!this.lineItem.extdata.e_VPE) return 1;
            
            return this.lineItem.extdata.e_VPE
                ? parseInt(this.lineItem.extdata.e_VPE.replace('*', '')) 
                : 1;
        },

        obligatedVpe() {
            return this.lineItem.extdata.e_VPE && this.lineItem.extdata.e_VPE.match(/[*]/) ? true : false
        },
    },

    methods: {
        ...mapActions('persistedCart', ['addItemToCart','removeItemFromCart']),

        onRemoveItemFromCartClick() {
            this.removeItemFromCart({
                lineItemId: this.lineItemId
            });
        },

        onChangeQuantity(newQuantity) {
            let oldQuantity = this.lineItem.amount;

            this.lineItem.amount = newQuantity;

            if (newQuantity === oldQuantity) {
                return;
            }

            // Add debounce to avoid multiple requests
            this.debouncedAddItemToCart();
        },

        debouncedAddItemToCart: _.debounce(function() {
            this.addItemToCart(this.lineItem);
        }, 500),

        toPrice(price) {
            return PriceHelper.toPrice(price);
        },
    },
};
</script>

<style scoped>
.cart_line-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
}

.cart_line-item--label {
    flex: 1;
}

.cart_line-item--price,
.cart_line-item--quantity,
.cart_line-item--total-price {
    flex: 0 0 100px;
    text-align: right;
}

.cart_line-item--quantity {
    flex: 0 0 150px;
}

.cart_line-item--actions {
    flex: 0 0 50px;
    text-align: right;
}
</style>