import ApiService from "./api.service.js";

function TransactionError(status, message) {
    this.status = status;
    this.message = message;
}

const TransactionService = {
    listTransactions: async function(data) {
        try {
            ApiService.setHeader();

            const response = await ApiService.post(process.env.VUE_APP_REQUEST_URL + "/transaction/" + data.accountId + "/list", data.params);

            return response.data.result;
        } catch (e) {
            throw new TransactionError(e, e);
        }
    },

    createTransaction: async function(transaction) {
        try {
            ApiService.setHeader();

            const response = await ApiService.post(process.env.VUE_APP_REQUEST_URL + "/transaction/create", transaction);

            return response.data.result;
        } catch (e) {
            throw new TransactionError(e, e);
        }
    },

    getTransaction: async function(data) {
        try {
            ApiService.setHeader();

            const response = await ApiService.post(process.env.VUE_APP_REQUEST_URL + "/transaction/" + data.transactionId + "/detail", data.params);

            return response.data.result.data;
        } catch (e) {
            throw new TransactionError(e, e);
        }
    },
};

export default TransactionService;
export { TransactionService, TransactionError };