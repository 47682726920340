import { ProductService, ProductError } from "@/services/product.service";

const state = {
    fetching: false,
    products: [],
    product: null,
};

const getters = {
    products: (state) => state.products,
    product: (state) => state.product
};

const actions = {
    async getProductList({ commit }, params) {
      try {
        const data = await ProductService.list(params);
      
        commit("LIST_PRODUCTS_SUCCESS", data);
      
        return true;
      } catch (error) {
        if (error instanceof ProductError) {
          commit("LIST_PRODUCTS_ERROR", {
            errorCode: error.status,
            errorMessage: error.message,
          });
        }
      
        return false;
      }
    },

    async showProduct({ commit }, params) {
      try {
        const product = await ProductService.list(params);
  
        commit("SHOW_PRODUCT_SUCCESS", product.data[0]);
  
        return true;
      } catch (error) {
        if (error instanceof ProductError) {
          commit("SHOW_PRODUCT_ERROR", {
            errorCode: error.status,
            errorMessage: error.message,
          });
        }
  
        return false;
      }
    }
};

const mutations = {
    LIST_PRODUCTS_SUCCESS(state, data) {
      state.products = data;
    },

    SHOW_PRODUCT_SUCCESS(state, product) {
      state.product = product;
    }
};

export const product = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
