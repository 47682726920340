import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: process.env.VUE_APP_PRIMARY_COLOR || '#34A8E5',
            secondary: process.env.VUE_APP_SECONDARY_COLOR || '#7FB018',
            success: process.env.VUE_APP_SUCCESS_COLOR || '#9ECE6A',
            warning: process.env.VUE_APP_WARNING_COLOR || '#FB8C00',
            error: process.env.VUE_APP_ERROR_COLOR || '#B71C1C',
          },
        },
    },

    icons: {
      iconfont: 'mdi', // default - only for display purposes
    },
});
