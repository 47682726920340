// @Depreciated

import _ from "lodash";
import StorageService from "@/services/storage.service"
import { OrderService } from "@/services/order.service"

const state = {
    cart: [],
    creatingOrder: false,
};

const getters = {
    cart: (state) => state.cart,
    creatingOrder: (state) => state.createOrder,
};

const actions = {
    upsertCartItem({ commit }, data) {
        commit("UPSERT_CART_ITEM_SUCCESS", data);
    },

    changeCartItemQuantity({ commit }, data) {
        commit("CHANGE_CART_ITEM_QUANTITY", data);
    },

    removeItemFromCart({ commit }, item) {
        commit("REMOVE_ITEM_FROM_CART_SUCCESS", item);
    },

    deleteCart({ commit }) {
        commit("DELETE_CART_SUCCESS")
    },

    async createOrder({ commit }, order) {
        commit('CREATING_ORDER');

        try {
            const data = await OrderService.createOrder(order);
      
            commit("CREATE_ORDER_SUCCESS", data);
            commit("DELETE_CART_SUCCESS");
      
            return true;
          } catch (error) {
            console.log("Order Error", error);

            commit("CREATE_ORDER_ERROR");
      
            return false;
          }
    }
};

const mutations = {
    CREATING_ORDER(state) {
        state.createOrder = true;
    },

    UPSERT_CART_ITEM_SUCCESS(state, { item, quantity }) {
        let index = _.findIndex(state.cart, ["item", item.item]);

        // increase item amount and recalculate totalprice
        if (index > -1) {
            state.cart[index].amount = state.cart[index].amount + quantity;
            state.cart[index].totaldiscount = (state.cart[index].amount * item.originalprice) - (state.cart[index].amount * item.sellingprice);
            state.cart[index].totalprice = state.cart[index].amount * item.sellingprice;
            StorageService.set("cart", JSON.stringify(state.cart));
            return;
        }

        let lineItem = { ...item };
        lineItem.amount = quantity;
        lineItem.totaldiscount = (quantity * item.originalprice) - (quantity * item.sellingprice);
        lineItem.totalprice = item.sellingprice * quantity;

        state.cart.push({ ...lineItem });
        StorageService.set("cart", JSON.stringify(state.cart));
    },

    CHANGE_CART_ITEM_QUANTITY(state, { item, quantity }) {
        let index = _.findIndex(state.cart, ["item", item.item]);

        // Change quantity according
        if (index > -1) {
            state.cart[index].amount = quantity;
            state.cart[index].totaldiscount = (state.cart[index].amount * item.originalprice) - (state.cart[index].amount * item.sellingprice);
            state.cart[index].totalprice = state.cart[index].amount * item.sellingprice;
            StorageService.set("cart", JSON.stringify(state.cart));
            return;
        }

        let lineItem = { ...item };
        lineItem.amount = quantity;
        lineItem.totaldiscount = (quantity * item.originalprice) - (quantity * item.sellingprice);
        lineItem.totalprice = item.sellingprice * quantity;

        state.cart.push({ ...lineItem });
        StorageService.set("cart", JSON.stringify(state.cart));
    },

    REMOVE_ITEM_FROM_CART_SUCCESS(state, item) {
        let index = _.findIndex(state.cart, ["item", item.item])
        if (index > -1) {
            state.cart.splice(index, 1);
            StorageService.set("cart", JSON.stringify(state.cart));
        }
    },

    DELETE_CART_SUCCESS(state) {
        state.cart = [];

        StorageService.set("cart", JSON.stringify(state.cart));
    },

    CREATE_ORDER_SUCCESS(state, data) {
        console.log("Order Success", data);
        state.createOrder = false;
    },

    CREATE_ORDER_ERROR(state) {
        state.createOrder = false;
    }
}

export const cart = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
