<template>
  <div id="cartWidget">
    <v-btn icon @click.stop="toggleCart()">
      <v-badge
        color="primary"
        :content="toPrice(cartTotalNet)"
        :value="cartItemsCount"
        bottom
        left
      >
        <v-icon>mdi-cart</v-icon>
      </v-badge>
      <v-badge
        color="primary"
        :content="cartItemsCount"
        :value="cartItemsCount"
      ></v-badge>
    </v-btn>

    <!-- Cart Dialog -->
    <v-dialog v-model="cartDialog" max-width="750">
      <v-card v-if="cartItemsCount">
        <v-card-title>
          <span class="headline">Warenkorb</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="toggleCart">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text v-if="!cart.data.lineItems">
          <p>Der Warenkorb ist leer.</p>
        </v-card-text>

        <v-card-text v-else>
          <v-alert
            v-if="hasRemovedItems"
            type="warning"
            dismissible
          >
            Einige Artikel sind nicht mehr verfügbar und wurden aus dem Warenkorb entfernt.
          </v-alert>

          <div class="cart_line-item heading">
            <div class="cart_line-item--label">Label</div>
            <div class="cart_line-item--price">Einzelpreis</div>
            <div class="cart_line-item--quantity">Menge</div>
            <div class="cart_line-item--total-price">Gesamtpreis</div>
            <div class="cart_line-item--actions"></div>
          </div>

          <cart-line-item
            v-for="(item, lineItemId) in cart.data.lineItems"
            :key="lineItemId"
            :cartId="cart.ID"
            :lineItemId="lineItemId"
            :lineItem="item"
          >
          </cart-line-item>

          <div class="cart_line-item total" v-if="cartTotalDiscount">
            <div class="cart_line-item--label"></div>
            <div class="cart_line-item--price"></div>
            <div class="cart_line-item--quantity">Nachlass</div>
            <div class="cart_line-item--total-price">-{{ toPrice(cartTotalDiscount) }}</div>
            <div class="cart_line-item--actions"></div>
          </div>

          <div class="cart_line-item total">
            <div class="cart_line-item--label"></div>
            <div class="cart_line-item--price"></div>
            <div class="cart_line-item--quantity">Summe (netto)</div>
            <div class="cart_line-item--total-price">{{ toPrice(cartTotalNet) }}</div>
            <div class="cart_line-item--actions"></div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn outlined :disabled="!cartItemsCount" @click.stop="removeCartAndCreateNewInstance" text>
            <v-icon color="red">mdi-close</v-icon>
            leeren
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            @click="toggleCart"
          >
            Weiter einkaufen
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="checkout"
          >
            Zur Kasse
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-else>
        <v-card-title>
          <span class="headline">Warenkorb</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="toggleCart">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p>Der Warenkorb ist leer.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="toggleCart"
          >
            Weiter einkaufen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CartLineItem from './CartLineItem.vue';
import { PriceHelper } from '../../../../helper/price.helper';

export default {
  components: {
    CartLineItem,
  },

  data: () => ({
    cartDialog: false,
    accountId: null,
  }),

  computed: {
    ...mapGetters('persistedCart', ['cart', 'cartItemsCount', 'cartTotalNet', 'cartTotalDiscount', 'hasRemovedItems']),
    ...mapGetters('auth', ['userId']),
    ...mapGetters('account', ['selectedAccount']),
  },

  created() {
    this.createdComponent();
  },

  methods: {
    ...mapActions('persistedCart', ['getCartByAccountId', 'removeCartByAccountId']),

    createdComponent() {
      this.accountId = this.selectedAccount?.ID ? this.selectedAccount.ID : this.userId;

      this.getCartByAccountId(this.accountId);
    },

    removeCartAndCreateNewInstance() {
      this.removeCartByAccountId(this.accountId);
      this.createdComponent();
    },

    toggleCart() {
      this.cartDialog = !this.cartDialog;
    },

    checkout() {
      this.toggleCart();
      this.$router.push('/checkout');
    },

    toPrice(sellingprice) {
      return PriceHelper.toPrice(sellingprice);
    },
  }
}
</script>

<style scoped>
.cart_line-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
}

.cart_line-item.heading {
    font-weight: bold;
}

.cart_line-item.total {
    border: none;
}

.cart_line-item--label {
    flex: 1;
    font-weight: bold;
}

.cart_line-item--price,
.cart_line-item--quantity,
.cart_line-item--total-price {
    flex: 0 0 100px;
    text-align: right;
    font-weight: bold;
}

.cart_line-item--quantity {
    flex: 0 0 150px;
}

.cart_line-item--actions {
    flex: 0 0 50px;
    text-align: right;
}
</style>