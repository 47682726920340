import { AuthService, AuthenticationError } from "@/services/auth.service";
import StorageService from "@/services/storage.service";
import ApiService from "@/services/api.service";

const state = {
  loggedIn: false,
  authenticating: false,
  userId: StorageService.get('user_id'),
  token: StorageService.get('token'),
  authenticationErrorCode: 0,
  authenticationError: "",
};

const getters = {
  loggedIn: (state) => {
    return state.token ? true : false;
  },

  userId: (state) => state.userId,

  authenticationErrorCode: (state) => {
    return state.authenticationErrorCode;
  },

  authenticationError: (state) => {
    return state.authenticationError;
  },

  authenticating: (state) => {
    return state.authenticating;
  },
};

const actions = {
  async login({ commit }, authData) {
    commit("LOGIN_REQUEST");

    try {
      const data = await AuthService.login(authData);

      if(data.token) {
        commit("LOGIN_SUCCESS", data);
      }

      return true;
    } catch (error) {
      if (error instanceof AuthenticationError) {
        commit("LOGIN_ERROR", {
          errorCode: error.status,
          errorMessage: error.message,
        });
      }

      return false;
    }
  },

  logout({ commit }) {
    commit("LOGOUT_SUCCES");
  },

  setToken({ commit }, token) {
    commit("SET_TOKEN", token);
  }
};

const mutations = {
  LOGIN_REQUEST(state) {
    state.authenticating = true;
    state.authenticationError = "";
    state.authenticationErrorCode = 0;
  },

  LOGIN_SUCCESS(state, data) {
    let token = `Bearer ${data.ID}:${data.token}`;

    StorageService.set('token', token);
    StorageService.set('user_id', data.ID);

    state.token = token;
    state.userId = data.ID;
    state.authenticating = false;
    state.loggedIn = true;

    ApiService.setHeader();
  },

  LOGIN_ERROR(state, { errorCode, errorMessage }) {
    state.authenticating = false;
    state.authenticationErrorCode = errorCode;
    state.authenticationError = errorMessage;
  },

  LOGOUT_SUCCES(state) {
    StorageService.set('token', "");
    StorageService.set('user_id', "");

    state.token = null;
    state.userId = null;
    state.loggedIn = false;

    ApiService.removeHeader();
  },

  SET_TOKEN(state, token) {
    let newToken = `Bearer ${state.userId}:${token}`;

    StorageService.set('token', newToken);

    ApiService.setHeader();
  }
};

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
