import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/modules/store/views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/store',
    name: 'Shop',
    component: () => import('@/modules/store/views/Store.vue'),
  },
  {
    path: '/store/:articleNumber',
    name: 'Product',
    component: () => import('@/modules/store/views/Store.vue'),
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('@/modules/store/views/Checkout.vue')
  },
  {
    path: '/account/orders',
    name: 'Bestellungen',
    component: () => import('@/modules/store/views/Transaction.vue')
  },
  {
    path: '/account/settings',
    name: 'Kundenkonto',
    component: () => import('@/modules/store/views/Account.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
