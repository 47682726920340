import ApiService from "./api.service.js";

function NoteError(status, message) {
    this.status = status;
    this.message = message;
}

const NoteService = {
    list: async function() {
        try {
            ApiService.setHeader();

            const response = await ApiService.get(process.env.VUE_APP_REQUEST_URL + "/note/list");

            return response.data.result;
        } catch (e) {
            throw new NoteError(e.response.status, e.response.data);
        }
    },

    show: async function(noteId) {
        try {
            ApiService.setHeader();

            const response = await ApiService.get(process.env.VUE_APP_REQUEST_URL + "/note/" + noteId + "/show");

            return response.data.result;
        } catch (e) {
            throw new NoteError(e.response.status, e.response.data);
        }
    },
};

export default NoteService;
export { NoteService, NoteError };