import ApiService from "./api.service.js";

function DashboardError(status, message) {
    this.status = status;
    this.message = message;
}

const DashboardService = {
    list: async function(data) {
        try {
            ApiService.setHeader();

            //const response = await ApiService.get(process.env.VUE_APP_REQUEST_URL + "/dashboard/list/" + data.params.account);
            const response = await ApiService.post(process.env.VUE_APP_REQUEST_URL + "/dashboard/" + data.accountId + "/list", data.params);
            return response.data.result;
        } catch (e) {
            throw new DashboardError(e.response.status, e.response.data);
        }
    },

    show: async function(dashboardId) {
        try {
            ApiService.setHeader();

            const response = await ApiService.get(process.env.VUE_APP_REQUEST_URL + "/dashboard/" + dashboardId + "/show");

            return response.data.result;
        } catch (e) {
            throw new DashboardError(e.response.status, e.response.data);
        }
    },
};

export default DashboardService;
export { DashboardService, DashboardError };