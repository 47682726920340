<template>
  <v-app>
    <!-- Login -->
    <v-main v-if="!loggedIn">
      <v-container fill-height v-if="loginForm">
        <v-row justify="center" align="center">
          <v-col cols="12" sm="6" lg="4" xl="3">
            <logo styleSettings="max-width: 300px; margin: 0 auto 1.5rem auto;" />

            <v-card elevation="0" outlined max-width="450">
              <v-card-title class="font-weight-light">Melden Sie sich an</v-card-title>
              <v-divider />
              <v-alert v-if="authenticationErrorCode" tile type="error" color="red lighten-1">Login nicht erfolgreich. Bitte versuchen Sie es erneut.</v-alert>
              <v-card-text>
                <p>Willkommen im B2B-Portal von <strong>{{ portalName }}</strong>.</p>
                <p>Melden Sie sich jetzt mit Ihrer Kundennummer oder Ihrer E-Mail-Adresse an.</p>
              </v-card-text>
              <v-card-text class="mt-n10">
                <v-text-field v-model="username" name="username" placeholder="E-Mail / Kundennummer" />
              </v-card-text>
              <v-card-text class="mt-n10">
                <v-text-field type="password" name="password" v-model="password" placeholder="Passwort" />
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-btn text @click.stop="showForm('lost-password')">Password vergessen?</v-btn>
                <v-spacer />
                <v-btn :disabled="!username || !password" @click.stop="handleLogin()" color="primary" elevation="0">Anmelden</v-btn>
              </v-card-actions>
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <v-btn v-if="registerEnabled" text @click.stop="showForm('register')">Noch kein Konto?</v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container fill-height v-if="registerForm">
        <v-row justify="center" align="center">
          <v-col cols="12" sm="6" lg="4" xl="3">
            <logo styleSettings="max-width: 300px; margin: 0 auto 1.5rem auto;" />
            
            <register-form @showLoginForm="showForm" />
          </v-col>
        </v-row>
      </v-container>

      <v-container fill-height v-if="lostPasswordForm">
        <v-row justify="center" align="center">
          <v-col cols="12" sm="6" lg="4" xl="3">
            <logo styleSettings="max-width: 300px; margin: 0 auto 1.5rem auto;" />
            
            <lost-password-form @showLoginForm="showForm"/>
          </v-col>
        </v-row>
      </v-container>

      <v-container fill-height v-if="resetPasswordForm">
        <v-row justify="center" align="center">
          <v-col cols="12" sm="6" lg="4" xl="3">
            <logo styleSettings="max-width: 300px; margin: 0 auto 1.5rem auto;" />
            
            <reset-password-form @showLoginForm="showForm"/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- Main View -->
    <v-main v-else>
        
      <v-navigation-drawer v-model="appDrawer" app color="primary">          
        <!-- User -->
        <template v-slot:prepend>
          <logo styleSettings="max-width: 300px; margin: 0 auto 1.5rem auto;" />
          <user :user="userAccount" />
        </template>

        <!-- Navigation -->
        <v-list
          dense
          nav
          dark
        >

          <!-- Dashboard link -->
          <v-list-item
            link
            to="/"
          >
            <v-list-item-icon>
              <v-icon>mdi-newspaper</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Neuigkeiten</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Store link -->
          <v-list-item
            link
            to="/store"
          >
            <v-list-item-icon>
              <v-icon>mdi-cart</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Store</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        
          <!-- Orders link -->
          <v-list-item
            link
            to="/account/orders"
          >
            <v-list-item-icon>
              <v-icon>mdi-order-bool-ascending-variant</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Bestellungen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Account link -->
          <v-list-item
            link
            to="/account/settings"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Kontoeinstellungen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>        
        </v-list>

        <!-- Logout button -->
        <template v-slot:append>
          <v-list
            dark
            dense
          >
            <!-- Logout button -->
            <v-list-item
              link
              @click.stop="handleLogout()"
            >
              <v-list-item-icon>
                <v-icon color="red lighten-2">mdi-logout</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Abmelden</v-list-item-title>
              </v-list-item-content>
            </v-list-item>        
          </v-list>
        </template>

      </v-navigation-drawer>
      
      <v-app-bar app flat color="#FFFFFF">
        <v-app-bar-nav-icon @click.stop="appDrawer = !appDrawer" />

        <v-toolbar-title>{{ currentRouteName }} | {{ portalName }}</v-toolbar-title>

        <v-spacer />

        <Cart />
      </v-app-bar>

      <!-- Main Content -->
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <update-banner />

    <v-footer fixed app inset>
      <v-container>
        <v-app-bar flat dense height="21">
          <v-spacer />
          <v-btn link text :href="infoLinks.imprint" target="_blank">Impressum</v-btn>
          <v-btn link text :href="infoLinks.agb" target="_blank">AGB</v-btn>
          <v-btn link text :href="infoLinks.privacy" target="_blank">Datenschutz</v-btn>
        </v-app-bar>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import user from "@/components/User";
import _ from "lodash";
import UpdateBanner from "@/components/UpdateBanner";
import LostPasswordForm from './modules/store/account/components/forms/LostPasswordForm.vue';
import ResetPasswordForm from './modules/store/account/components/forms/ResetPasswordForm.vue';
import RegisterForm from './modules/store/account/components/forms/RegisterForm.vue';
import Logo from './components/Logo.vue';
import Cart from '@/modules/store/components/cart/Cart.vue';

export default {
  name: 'App',

  components: {
    user,
    Cart,
    UpdateBanner,
    RegisterForm,
    LostPasswordForm,
    ResetPasswordForm,
    Logo,
  },

  data: () => ({
    logo: process.env.VUE_APP_STORE_LOGO || null,
    username: null,
    password: null,
    cartDialog: false,
    accountDialog: false,
    appDrawer: true,
    loginForm: true,
    registerForm: false,
    lostPasswordForm: false,
    resetPasswordForm: false,
  }),

  created() {
    console.log('Version', '1.3.3');

    if (this.$route.query.reset) {
      this.loginForm = false;
      this.resetPasswordForm = true;
    }

    if (!this.loggedIn) {
      return;
    }

    document.title = process.env.VUE_APP_PORTAL_NAME;
    document.querySelector("link[rel*='icon']").href = process.env.VUE_APP_STORE_FAVICON;
    
    this.init()
  },

  computed: {
    ...mapGetters("auth", ["loggedIn", "userId", "authenticationErrorCode"]),
    ...mapGetters("cart", ["cart"]),
    ...mapGetters("account", ["userAccount"]),

    currentRouteName() {
        return this.$route.name;
    },

    portalName() {
      return process.env.VUE_APP_PORTAL_NAME
    },

    registerEnabled() {
      return process.env.VUE_APP_REGISTER
    },
    totalNet() {
            return _.sumBy(this.cart, "totalprice")
    },

    infoLinks() {
      return {
        imprint: process.env.VUE_APP_LINK_IMPRINT,
        privacy: process.env.VUE_APP_LINK_PRIVACY,
        agb: process.env.VUE_APP_LINK_AGB
      }
    }
  },

  watch: {
    loggedIn(val) {
      if (!val) {
        return;
      }

      this.init()
    }
  },

  methods: {
    ...mapActions("auth", ["login", "logout"]),
    ...mapActions("app", ["getConfig"]),
    ...mapActions("account", ["getUserAccount", "getSelectedAccounts", "getAssignedAccount"]),
    ...mapActions("cart", ["deleteCart"]),

    init() {
      this.getUserAccount(this.userId)
      this.getConfig();
    },
    toPrice(sellingprice) {
            return parseFloat(sellingprice).toFixed(2).replace('.',',') + "€";
    },
    handleLogin() {
      if (!this.username || !this.password) return;

      this.login({ username: this.username, password: this.password })
        .then(() => {
          this.username = null;
          this.password = null;

          this.$router.push('/');
        })
    },

    handleLogout() {
      this.deleteCart()
        .then(() => {
          this.username = null;
          this.password;

          this.logout()
        })
    },

    toggleCart() {
      this.cartDialog = !this.cartDialog;
    },

    onToggleFormClick() {
      this.register = !this.register;
    },

    showForm(form = 'login') {
      this.loginForm = false;
      this.registerForm = false;
      this.lostPasswordForm = false;

      if (form === 'login') this.loginForm = true;
      if (form === 'register') this.registerForm = true;
      if (form === 'lost-password') this.lostPasswordForm = true;
    }
  }
};
</script>
