import ApiService from "./api.service.js";

function ConfigError(status, message) {
    this.status = status;
    this.message = message;
}

const ConfigService = {
    getConfig: async function() {
        try {
            ApiService.setHeader();

            const response = await ApiService.get(process.env.VUE_APP_REQUEST_URL + "/config");

            return response.data.result;
        } catch (e) {
            throw new ConfigError(e.response.status, e.response.data);
        }
    },
};

export default ConfigService;
export { ConfigService, ConfigError };