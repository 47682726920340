<template>    
  <v-container fluid>
    
    
    <div v-for="alert in alerts" :key="alert.ID">
        <v-alert  dense border="left" :type="alert.type" v-if="alert.enabled">
           {{alert.text}}           
        </v-alert>
    </div>
      <v-tabs v-model="tab">
            <v-tab>News</v-tab>    
            <!--v-tab>Dashboard</v-tab-->
           
        </v-tabs>
    <v-tabs-items v-model="tab">
             <!-- Tab Quotes -->
            <v-tab-item>
                <div v-for="note in news" :key="note.ID">
                    <v-card class="mb-5" elevation="0" outlined v-if="note.body">
                        <v-card-text v-html="note.body" class="clearfix"></v-card-text>
                    </v-card>
                </div>
                
                <v-dialog v-model="noteDialog" max-width="700">
                    <v-card>
                        <v-card-text class="pt-5" v-html="note" />
                    </v-card>
                </v-dialog>
             </v-tab-item>

            <!-- Tab Invoices -->
            <v-tab-item>
                <div v-for="dashboard in feeds" :key="dashboard.ID">
                    <v-card class="mb-5" elevation="0" outlined v-if="dashboard.body">
                          <v-card-title>{{dashboard.title}}</v-card-title>
                        <v-card-text v-html="dashboard.body" class="clearfix"></v-card-text>
                    </v-card>
                </div>
            </v-tab-item>
        </v-tabs-items>
  </v-container>
</template>

<script>
import { NoteService } from '@/services/note.service';
import { DashboardService } from '@/services/dashboard.service';
import {  mapGetters } from "vuex";
export default {
    data: () => ({
        tab: null,
        notes: [],
        note: {},
        dashboards: [],
        dashboard: {},
        alert:{},
        noteDialog: false,
    }),

    created() {
        this.getNotes();
        this.getDashboards();    
    },

    computed: {
        ...mapGetters("app", ["config"]),
        news: function() {
            return this.notes?.data;
        },
        feeds: function() {
            return this.dashboards?.data;
        },
        alerts: function() {
            return  this.config?.app_alerts; 
        }
    },
    
    methods: {
        getNotes() {
            NoteService.list()
                .then((res) => {
                    this.notes = res;
                })
        },

        showNote(noteId) {
            NoteService.show(noteId)
                .then((res) => {
                    this.note = res;
                    this.noteDialog = !this.noteDialog;
                })
        },
        /*
        getDashboards() {
            DashboardService.list()
                .then((res) => {
                    this.dashboards = res;
                })
        },
        */
         getDashboards(offset = 0) {
            DashboardService.list({
                accountId: this.userId,
                params: {
                    account: this.accountId,
                    type: 2,
                    limit: 50,
                    offset: offset
                }
            })
        },
    }
}
</script>

<style>
    .clearfix:after {
        content: " ";
        display: block;
        clear: both;
    }
</style>