import ApiService from "./api.service.js";

function AuthenticationError(status, message) {
    this.status = status;
    this.message = message;
}

const AuthService = {
    login: async function(authData) {
        try {
            ApiService.removeHeader();

            const response = await ApiService.post(process.env.VUE_APP_REQUEST_URL + "/auth", authData);

            return response.data.result;
        } catch (e) {
            throw new AuthenticationError(e.response.status, e.response.data);
        }
    },
};

export default AuthService;
export { AuthService, AuthenticationError };