<template>
    <div class="quantity-select">
        <v-btn :disabled="!quantity" icon @click.stop="decrement">
            <v-icon>mdi-minus</v-icon>
        </v-btn>

        <v-text-field
            type="number"
            outlined
            v-model="quantity"
            :step="step"
            :min="step"
            single-line 
            dense
            hide-details 
            placeholder="Menge"
            @keyup.enter="onPressEnter"
        >
        </v-text-field>

        <v-btn icon @click.stop="increment">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ItemQuantity',

  props: ['amount', 'vpe', 'obligatedVpe', 'allowZero'],

  data: () => ({
    quantity: 0
  }),

  computed: {
    step() {
        return this.obligatedVpe ? this.vpe : 1;
    }
  },

  watch: {
    amount(newVal) {
        this.quantity = newVal;
    },

    quantity(newVal) {
        this.updateQuantity(newVal);
    }
  },

  created() {
    this.quantity = this.amount;
  },

  methods: {
    increment() {
      this.quantity += this.step;
    },

    decrement() {
        this.quantity -= this.step;
    },

    onPressEnter() {
      this.$emit('on-press-enter', this.quantity);
    },

    updateQuantity: _.debounce(function(newVal) {
      this.quantity = parseInt(newVal);

        if (this.quantity < this.step && !this.allowZero) {
            this.quantity = this.step;
        }

        if (this.obligatedVpe && this.quantity % this.step !== 0) {
            this.quantity = Math.ceil(this.quantity / this.step) * this.step;
        }
        
        this.$emit('on-change-quantity', this.quantity);
    }, 800)
  }
}
</script>

<style scoped>
.quantity-select {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
}
</style>