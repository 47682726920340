import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from "@/store/modules/auth.module";
import { app } from "@/store/modules/app.module";
import { account } from "@/modules/store/account/store/account.module";
import { product } from "@/store/modules/product.module";
import { cart } from "@/store/modules/cart.module";
import { persistedCart } from "@/store/modules/persistedCart.module";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    app,
    product,
    cart,
    persistedCart,
    account,
  }
})
