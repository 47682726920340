<template>
    <v-list-item two-line dark>
        <v-list-item-avatar color="secondary">
            <v-icon>mdi-account</v-icon>
        </v-list-item-avatar>

        <v-list-item-content v-if="user">
            <v-list-item-subtitle>Willkommen</v-list-item-subtitle>
            <v-list-item-title>{{ user.lastname }}</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: "user",

    props: ["user"],
}
</script>
