import ApiService from "@/services/api.service.js";

function AccountError(status, message) {
    this.status = status;
    this.message = message;
}

const AccountService = {
    getAccount: async function(accountId) {
        try {
            ApiService.setHeader();

            const response = await ApiService.get(process.env.VUE_APP_REQUEST_URL + "/account/" + accountId + "/detail");

            return response.data.result.data;
        } catch (e) {
            throw new AccountError(e.response.status, e.response.data);
        }
    },

    searchAccounts: async function(query) {
        try {
            ApiService.setHeader();

            const response = await ApiService.post(process.env.VUE_APP_REQUEST_URL + "/account/search/", query);

            return response.data.result.data;
        } catch (e) {
            throw new AccountError(e.response.status, e.response.data);
        }        
    },

    changePassword: async function(params) {
        try {
            ApiService.setHeader();

            const response = await ApiService.post(process.env.VUE_APP_REQUEST_URL + "/account/change-password", params);

            return response.data.result;
        } catch (e) {
            throw new AccountError(e.response.status, e.response.data);
        }        
    },

    lostPassword: async function(params) {
        try {
            const response = await ApiService.post(process.env.VUE_APP_REQUEST_URL + "/account/lost-password", params);

            return response.data.result;
        } catch (e) {
            throw new AccountError(e.response.status, e.response.data);
        }        
    },

    resetPassword: async function(params) {
        try {
            const response = await ApiService.post(process.env.VUE_APP_REQUEST_URL + "/account/reset-password", params);

            return response.data.result;
        } catch (e) {
            throw new AccountError(e.response.status, e.response.data);
        }        
    },

    registerAccount: async function(params) {
        try {
            const response = await ApiService.post(process.env.VUE_APP_REQUEST_URL + "/account/register/", params);

            return response.data.result;
        } catch (e) {
            throw new AccountError(e.response.status, e.response.data);
        } 
    }
};

export default AccountService;
export { AccountService, AccountError };