import AccountService from "../services/account.service";
import { OrderService } from "@/services/order.service";
import { TransactionService } from "@/services/transaction.service";

const state = {
    userAccount: null,
    selectedAccount: null,
    orders: null,
    transaction: null,
    deliveries: null,
    quotes: null,
    invoices: null
};

const getters = {
    userAccount: (state) => state.userAccount,
    selectedAccount: (state) => state.selectedAccount,
    quotes: (state) => state.quotes,
    orders: (state) => state.orders,
    transaction: (state) => state.transaction,
    deliveries: (state) => state.deliveries,
    invoices: (state) => state.invoices,
};

const actions = {
    async getUserAccount({ commit }, accountId) {
        try {
            const account = await AccountService.getAccount(accountId);
            commit("GET_USER_ACCOUNT_SUCCESS", account)
        } catch (error) {
            console.error(error);
        }
    },

    async getAssignedAccount({ commit }, accountId) {
        try {
            const account = await AccountService.getAccount(accountId);
            commit("GET_ASSIGNED_ACCOUNT_SUCCESS", account) 
        } catch (error) {
            console.error(error);
        }
    },

    unsetAccount({ commit }) {
        commit("UNSET_ACCOUNT");
    },

    async getTransactions({ commit }, data) {
        try {
            const transactionsData = await TransactionService.listTransactions(data);

            commit("LIST_TRANSACTIONS_SUCCESS", transactionsData);

            return true;
        } catch (error) {
            console.log("Get Transactions Error", error);

            return false;
        }
    },

    async getQuotes({ commit }, data) {
        try {
            const quotes = await TransactionService.listTransactions({ accountId: data.accountId, params: data.params ? data.params : {} });
      
            commit("LIST_QUOTES_SUCCESS", quotes);
      
            return true
          } catch (error) {
            console.log("List Quotes Error", error);
      
            return false
          }
    },

    async getOrders({ commit }, data) {
        try {
            const orders = await OrderService.listOrders(data.accountId, data.params);
      
            commit("LIST_ORDERS_SUCCESS", orders);
      
            return true;
          } catch (error) {
            console.log("List Orders Error", error);
      
            return false;
          }
    },

    async getTransaction({ commit }, data) {
        try {
            const transaction = await TransactionService.getTransaction(data);
      
            commit("GET_TRANSACTION_SUCCESS", transaction);
      
            return true;
          } catch (error) {
            console.log("Get Transaction Error", error);
      
            return false;
          }
    },

    async getDeliveries({ commit }, data) {
        try {
            const deliveries = await TransactionService.listTransactions(data);
      
            commit("LIST_DELIVERIES_SUCCESS", deliveries);
      
            return true
          } catch (error) {
            console.log("List Deliveries Error", error);
      
            return false
          }
    },

    async getInvoices({ commit }, data) {
        try {
            const invoices = await TransactionService.listTransactions({ accountId: data.accountId, params: data.params ? data.params : {} });
      
            commit("LIST_INVOICES_SUCCESS", invoices);
      
            return true
          } catch (error) {
            console.log("List Invoices Error", error);
      
            return false
          }
    },
}

const mutations = {
    GET_USER_ACCOUNT_SUCCESS(state, account) {
        account?.accesslist ? account['isCommAgent'] = true : account['isCommAgent'] = false;

        state.userAccount = account;
        state.selectedAccount = null;
        state.orders = null;
        state.order = null;
        state.deliveries = null;
        state.invoices = null;
        state.qoutes = null;    
    },

    GET_ASSIGNED_ACCOUNT_SUCCESS(state, account) {
        state.selectedAccount = account;
    },

    UNSET_ACCOUNT(state) {
        state.selectedAccount = null;
    },

    LIST_TRANSACTIONS_SUCCESS(state, transactionsData) {
        console.log(transactionsData);
        if (transactionsData.type === 0) {
            state.quotes = transactionsData;

            return true;
        }

        if (transactionsData.type === 1) {
            state.orders = transactionsData;

            return true;
        }

        if (transactionsData.type === 2) {
            state.deliveries = transactionsData;

            return true;
        }

        if (transactionsData.type === 3) {
            state.invoices = transactionsData;

            return true;
        }
    },

    LIST_QUOTES_SUCCESS(state, quotes) {
        state.quotes = quotes;
    },

    LIST_ORDERS_SUCCESS(state, orders) {
        state.orders = orders;
    },

    LIST_DELIVERIES_SUCCESS(state, deliveries) {
        state.deliveries = deliveries;
    },

    LIST_INVOICES_SUCCESS(state, invoices) {
        state.invoices = invoices;
    },

    GET_TRANSACTION_SUCCESS(state, transaction) {
        state.transaction = transaction;
    },
}

export const account = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
