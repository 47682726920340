import { ConfigService } from "@/services/config.service";

const state = {
    config: {},
};

const getters = {
    config: (state) => state.config,
};

const actions = {
    async getConfig({ commit }) {
        try {
            const data = await ConfigService.getConfig();
      
            commit("GET_CONFIG_SUCCESS", data);
      
            return true;
          } catch (error) {
            console.log("Config Error", error)
      
            return false;
          }
    }
};

const mutations = {
    GET_CONFIG_SUCCESS(state, data) {
        state.config = data;
    }
};

export const app = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};