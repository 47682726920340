<template>
  <v-card tile class="px-5 py-5" elevation="0">
    <v-img :src="logo" alt="Store Logo" :style="styleSettings" />
  </v-card>
</template>

<script>
export default {
    props: ['styleSettings'],

    data: () => ({
        logo: process.env.VUE_APP_STORE_LOGO
    })
}
</script>

<style>

</style>