import ApiService from "./api.service.js";

function ProductError(status, message) {
    this.status = status;
    this.message = message;
}

const ProductService = {
    list: async function(data) {
        try {
            ApiService.setHeader();

            const response = await ApiService.post(process.env.VUE_APP_REQUEST_URL + "/product/list", data);

            return response.data.result;
        } catch (e) {
            throw new ProductError(e.response.status, e.response.data);
        }
    },
};

export default ProductService;
export { ProductService, ProductError };