<template>
    <div>
        <v-card elevation="0" outlined max-width="450" v-if="!formSuccess">
            <v-card-title class="font-weight-light">Passwort vergessen?</v-card-title>
            <v-divider />
            <v-alert
                v-if="hasFormErrors"
                tile 
                type="error" 
                color="red lighten-1"
            >
                {{ formError }}
            </v-alert>
            <v-card-text>
                <p>Geben Sie Ihr neues Passwort ein und fahren Sie mit einem Klick auf "Neues Passwort speichern" fort.</p>
            </v-card-text>
            <v-card-text class="mt-n10">
                <v-text-field v-model="password" name="password" placeholder="Passwort" type="password" required />
            </v-card-text>
            <v-card-text class="mt-n10">
                <v-text-field v-model="passwordConfirm" name="passwordConfirm" placeholder="Passwort bestätigen" type="password" required />
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn text @click.stop="showLoginForm()">
                    <v-icon class="mr-2">mdi-arrow-left</v-icon>
                    Zum Login
                </v-btn>
                <v-spacer />
                <v-btn :disabled="isDisabled" @click.stop="handleSavePasswordClick()" color="primary" elevation="0">Neues Passwort speichern</v-btn>
            </v-card-actions>
        </v-card>

        <v-card elevation="0" color="success" dark max-width="450" v-else>
            <v-card-title>
                Neues Passwort wurde gespeichert
            </v-card-title>
            <v-divider />
            <v-card-text>
                Ihr neues Passwort wurde erfolgreich gespeichert. Kehren Sie zum Login zurück.
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn text @click.stop="showLoginForm()">
                    <v-icon class="mr-2">mdi-arrow-left</v-icon>
                    Zum Login
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import AccountService from "@/modules/store/account/services/account.service";

export default {
    data: () => ({
        password: null,
        passwordConfirm: null,
        formError: null,
        formSuccess: false,
        hasFormErrors: false,
        isDisabled: false
    }),

    methods: {
        showLoginForm() {
            this.$emit('showLoginForm');
        },

        handleSavePasswordClick() {
            this.hasFormErrors = false;

            if (!this.password) {
                this.formError = 'Bitte geben Sie Ein neues Password ein!';
                this.hasFormErrors = true;

                return false;
            }

            if (this.password != this.passwordConfirm) {
                this.formError = 'Passwörter stimmen nicht über ein!';
                this.hasFormErrors = true;

                return false;                
            }

            AccountService.resetPassword({ password: this.password, hash: this.$route.query.reset })
                .then((res) => {
                    if (res && res.error.errorCode === 'INVALID_HASH') {
                        this.formError = 'Passwortänderung nicht möglich. Der Link ist nicht (mehr) gültig!';
                        this.hasFormErrors = true;

                        return false;
                    }

                    this.$router.push('/')
                    this.formSuccess = true;
                });
        }
    }
}
</script>

<style>

</style>