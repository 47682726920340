/**
 * API Service
 *
 * This is a wrapper for API Calls via axios.
 */

import axios from "axios";
import StorageService from "@/services/storage.service";

const ApiService = {
  /**
   * Init Api Service by setting the default url
   *
   * @param baseURL
   * @method init
   */
  init(baseURL) {
    axios.defaults.baseURL = baseURL;
  },

  setAdapter() {
    axios.defaults.adapter = require("axios/lib/adapters/http");
  },

  /**
   * Set request header for authorization
   *
   * @method setHeader()
   */
  setHeader() {
    axios.defaults.headers["X-Auth"] = `${StorageService.get('token')}`;
    axios.defaults.headers["Content-Type"] = "application/json";
  },

  /**
   * Remove header
   *
   * @method removeHeader
   */
  removeHeader() {
    axios.defaults.headers = {};
  },

  /**
   * API GET request
   *
   * @param resource
   * @method get
   */
  get(resource, data) {
    return axios.get(resource, data);
  },

  /**
   * API POST request
   *
   * @param resource
   * @method post
   */
  post(resource, data) {
    return axios.post(resource, data);
  },

  /**
   * API PUT request
   *
   * @param resource
   * @method put
   */
  put(resource, data) {
    return axios.put(resource, data);
  },

  /**
   * API PATCH request
   *
   * @param resource
   * @method patch
   */
  patch(resource, data) {
    return axios.patch(resource, data);
  },

  /**
   *  API DELETE request
   *
   * @param resource
   * @method delete
   */
  delete(resource, data) {
    return axios.delete(resource, data);
  },

  /**
   * API custom request
   *
   * @method customRequest
   */
  customRequest(data) {
    return axios(data);
  },
};

export default ApiService;
