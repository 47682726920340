import ApiService from "./api.service.js";

function OrderError(status, message) {
    this.status = status;
    this.message = message;
}

const OrderService = {
    createOrder: async function(order) {
        try {
            ApiService.setHeader();

            const response = await ApiService.post(process.env.VUE_APP_REQUEST_URL + "/order/create", order);

            return response.data.result;
        } catch (e) {
            throw new OrderError(e.response.status, e.response.data);
        }
    },

    listOrders: async function(accountId, params = {}) {
        try {
            ApiService.setHeader();

            const response = await ApiService.post(process.env.VUE_APP_REQUEST_URL + "/order/" + accountId + "/list", params);

            return response.data.result;
        } catch (e) {
            throw new OrderError(e.response.status, e.response.data);
        }
    },

    getOrder: async function(orderId) {
        try {
            ApiService.setHeader();

            const response = await ApiService.get(process.env.VUE_APP_REQUEST_URL + "/order/" + orderId + "/detail");

            return response.data.result.data;
        } catch (e) {
            throw new OrderError(e.response.status, e.response.data);
        }
    },
};

export default OrderService;
export { OrderService, OrderError };