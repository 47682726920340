import ApiService from '@/services/api.service';

const state = {
    cart: null,
};

const getters = {
    cart: (state) => state.cart,

    lineItems: (state) => state.cart ? Object.values(state.cart.data.lineItems) : [],

    cartTotalNet: (state) => state.cart ? state.cart.data.totalNet : 0,

    cartTotalDiscount: (state) => state.cart ? state.cart.data.totalDiscount : 0,

    cartTax: (state) => state.cart ? state.cart.data.tax : 0,

    cartItemsCount: (state) => state.cart ? Object.keys(state.cart.data.lineItems).length : 0,

    hasRemovedItems: (state) => state.cart ? state.cart.removedLineItems.length : false,
};

const actions = {
    async getCartByAccountId({ commit }, accountId) {
        try {
            const response = await ApiService.get(process.env.VUE_APP_REQUEST_URL + '/cart/' + accountId);
            commit('GET_CART_SUCCESS', response.data.result.data.cart);
        } catch (error) {
            console.log('Get Cart Error', error);
            commit('GET_CART_ERROR');
        }
    },

    async addItemToCart({ commit }, item) {
        try {
            const response = await ApiService.post(process.env.VUE_APP_REQUEST_URL + '/cart/' + state.cart.ID + '/item', item);
            commit('GET_CART_SUCCESS', response.data.result.data.cart);
        } catch (error) {
            console.log('Add Item to Cart Error', error);
        }
    },

    async removeItemFromCart({ commit }, { lineItemId }) {
        try {
            const response = await ApiService.delete(process.env.VUE_APP_REQUEST_URL + '/cart/' + state.cart.ID + '/lineItem/' + lineItemId);
            commit('GET_CART_SUCCESS', response.data.result.data.cart);
        } catch (error) {
            console.log('Remove Item from Cart Error', error);
        }
    },

    async removeCartByAccountId({ commit }, accountId) {
        try {
            await ApiService.delete(process.env.VUE_APP_REQUEST_URL + '/cart/' + accountId);
            commit('REMOVE_CART_SUCCESS', true);
        } catch (error) {
            console.log('Remove Cart Error', error);
        }
    },
};

const mutations = {
    GET_CART_SUCCESS(state, cart) {
        state.cart = cart;
    },

    REMOVE_CART_SUCCESS(state) {
        state.cart = null;
    },
};

export const persistedCart = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};