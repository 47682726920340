<template>
    <div>
        <v-card elevation="0" outlined max-width="450" v-if="!registerSuccess">
            <v-card-title class="font-weight-light">Erstellen Sie ein neues Konto</v-card-title>
            <v-divider />
            <v-alert
                v-if="hasFormErrors"
                tile 
                type="error" 
                color="red lighten-1"
            >
                {{ registerError }}
            </v-alert>
            <v-card-text>
                <p>Registrieren Sie sich hier für ein Konto im Händlerportal. Nach der Registrierung werden wir Ihr Konto prüfen und Sie nach der Freischaltung per Email benachrichtigen.</p>
            </v-card-text>
            <v-card-text class="mt-n10">
                <v-text-field v-model="company" name="company" placeholder="Firma" required />
            </v-card-text>
            <v-card-text class="mt-n10">
                <v-text-field v-model="taxid" name="taxid" placeholder="Umsatzsteuer-ID (wenn vorhanden)" />
            </v-card-text>
            <v-card-text class="mt-n10">
                <v-text-field v-model="lastname" name="lastname" placeholder="Nachname" required />
            </v-card-text>
            <v-card-text class="mt-n10">
                <v-text-field v-model="firstname" name="firstname" placeholder="Vorname" required />
            </v-card-text>
            <v-card-text class="mt-n10">
                <v-text-field v-model="phone" name="phone" placeholder="Telefon (optional)" />
            </v-card-text>
            <v-card-text class="mt-n10">
                <v-text-field v-model="email" name="email" placeholder="E-Mail" required />
            </v-card-text>
            <v-card-text class="mt-n10">
                <v-text-field type="password" name="password" v-model="password" placeholder="Passwort" required />
            </v-card-text>
            <v-card-text class="mt-n10">
                <v-text-field v-model="street" name="street" placeholder="Straße &amp; Hausnummer" required />
            </v-card-text>
            <v-card-text class="mt-n10">
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="postalcode" name="postalcode" placeholder="Postleitzahl" required />
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-text-field v-model="city" name="city" placeholder="Ort" required />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text class="mt-n10">
                <v-select :items="countries" v-model="country" name="country" placeholder="Land" required />
            </v-card-text>
            <v-card-text class="d-flex align-baseline mt-n5">
                <v-checkbox v-model="acceptedPrivacyTerms" color="primary" />
                <p v-html="privacyLabel"></p>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn text @click.stop="showLoginForm()">
                    <v-icon class="mr-2">mdi-arrow-left</v-icon>
                    Zum Login
                </v-btn>
                <v-spacer />
                <v-btn :disabled="isDisabled" @click.stop="handleRegister()" color="primary" elevation="0">Registrieren</v-btn>
            </v-card-actions>
        </v-card>

        <v-card elevation="10" color="success" dark max-width="450" v-else>
            <v-card-title>
                Registrierung erfolgreich übermittelt
            </v-card-title>
            <v-divider />
            <v-card-text>
                Ihre Registrierung wurde erfolgreich übermittelt. Sobald Ihr Konto geprüft und freigeschaltet wurde, werden wir Sie umgehend per Email benachrichtigen.
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn text @click.stop="showLoginForm()">
                    <v-icon class="mr-2">mdi-arrow-left</v-icon>
                    Zum Login
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import AccountService from "@/modules/store/account/services/account.service";

export default {
    data: () => ({
        company: null,
        taxid: null,
        lastname: null,
        firstname: null,
        phone: null,
        email: null,
        street: null,
        postalcode: null,
        city: null,
        countries: [
            {
                text: "Deutschland",
                value: "DE"
            }
        ],
        country: null,
        password: null,
        registerError: null,
        registerSuccess: false,
        hasFormErrors: false,
        acceptedPrivacyTerms: false
    }),

    computed: {
        isDisabled() {
            return false;
        },

        privacyLabel() {
            return `Ich habe die <a href="${process.env.VUE_APP_LINK_PRIVACY}" target="_blank">Datenschutzbestimmungen</a> zur Kenntnis genommen.`;
        }
    },

    methods: {
        handleRegister() {
            this.hasFormErrors = false;
            this.registerError = null;

            if (!this.validate()) {
                this.hasFormErrors = true;
                this.registerError = "Bitte füllen Sie alle Pflicht-Eingabefelder aus!";
                return false;
            }

            AccountService.registerAccount({
                company: this.company,
                taxid: this.taxid,
                lastname: this.lastname,
                firstname: this.firstname,
                phone: this.phone,
                email: this.email,
                password: this.password,
                address: this.street,
                postalcode: this.postalcode,
                city: this.city,
                country: this.country
            })
                .then((res) => {
                    if (res.error) {
                        this.hasFormErrors = true;

                        if (res.error.errorCode === "ACCOUNT_WITH_EMAIL_ALREADY_EXISTS") {
                            this.registerError = "Ein Konto mit dieser Email-Adresse existiert bereits!";
                        }
                        return false;
                    }

                    this.registerSuccess = true;
                });
        },

        validate() {
            if (!this.acceptedPrivacyTerms) {
                return false;
            }

            if (!this.company) {
                return false;
            }

            if (!this.lastname) {
                return false;
            }

            if (!this.firstname) {
                return false;
            }

            if (!this.email) {
                return false;
            }

            if (!this.password) {
                return false;
            }

            if (!this.street) {
                return false;
            }

            if (!this.postalcode) {
                return false;
            }

            if (!this.city) {
                return false;
            }

            if (!this.country) {
                return false;
            }

            return true;
        },

        showLoginForm() {
            this.$emit('showLoginForm');
        }
    }
}
</script>

<style>

</style>