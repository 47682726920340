<template>
    <div>
        <v-card elevation="0" outlined max-width="450" v-if="!formSuccess">
            <v-card-title class="font-weight-light">Passwort vergessen?</v-card-title>
            <v-divider />
            <v-alert
                v-if="hasFormErrors"
                tile 
                type="error" 
                color="red lighten-1"
            >
                {{ formError }}
            </v-alert>
            <v-card-text>
                <p>Geben Sie Ihre im System hinterlegte Email-Adresse an. Sie erhalten anschließend eine Email mit einem Link zur Passwort-Wiederherstellung.</p>
            </v-card-text>
            <v-card-text class="mt-n10">
                <v-text-field v-model="email" name="email" placeholder="E-Mail" required />
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn text @click.stop="showLoginForm()">
                    <v-icon class="mr-2">mdi-arrow-left</v-icon>
                    Zum Login
                </v-btn>
                <v-spacer />
                <v-btn :disabled="isDisabled" @click.stop="handleResetPasswordClick()" color="primary" elevation="0">wiederherstellen</v-btn>
            </v-card-actions>
        </v-card>

        <v-card elevation="0" color="success" dark max-width="450" v-else>
            <v-card-title>
                Neues Passwort angefordert
            </v-card-title>
            <v-divider />
            <v-card-text>
                Sollte Ihre Email-Adresse in unserem System vorhanden sein, erhalten Sie gleiche eine Email mit einem Link zur Passwort-Wiederherstellung.
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn text @click.stop="showLoginForm()">
                    <v-icon class="mr-2">mdi-arrow-left</v-icon>
                    Zum Login
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import AccountService from "@/modules/store/account/services/account.service";

export default {
    data: () => ({
        email: null,
        formError: null,
        formSuccess: false,
        hasFormErrors: false,
        isDisabled: false
    }),

    methods: {
        showLoginForm() {
            this.$emit('showLoginForm');
        },

        handleResetPasswordClick() {
            this.hasFormErrors = false;

            if (!this.email) {
                this.formError = 'Bitte geben Sie Ihre Email-Adresse an!';
                this.hasFormErrors = true;

                return false;
            }

            AccountService.lostPassword({ email: this.email })
                .then(() => {
                    this.formSuccess = true;
                });
        }
    }
}
</script>

<style>

</style>